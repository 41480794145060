import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Menu as MoreMenu, MenuItem } from '@mui/material';
import Menu from 'components/common/MenuBar';
import { Clickable } from 'phoenix-components';
import { hamIcon, newCartIcon, newSearchIcon } from 'components/shared/Icons/Header';
import { useRouter } from 'next/router';
import { useIsCustomDomain, useIsGuest, useIsVisitor, useShowLogin } from 'contexts/profile';
import { useQueryParams } from 'hooks/location';
import CustomShopChat from 'components/shop/CustomShopChat';
import { getUserIcon } from 'components/utils/icon';
import styles from './Header.module.css';
import { useLanguageLoader } from 'contexts/language';
import { useThemeName } from 'hooks/shop';
import { useAutoFontSize } from 'hooks/styles';
import { useLocalCart } from 'contexts/cart';

function Header({
  // showProfile,
  showSearch,
  showMenu,
  showBack,
  onBack,
  showLogout,
  showCart,
  payload,
  showShop,
  dataId,
  text,
  headerStyles,
  label,
  showChat,
  shop,
}) {

  const router = useRouter();
  const isVisitor = useIsVisitor();
  const isGuest = useIsGuest();
  const showLogin = useShowLogin(shop);
  const params = useQueryParams();
  const lang = useLanguageLoader();
  const menu = params.has('openMenu');
  const showLogoutMenu = params.has('showLogout');
  const stepper = params.has('stepper');
  const address = params.has('addressId');
  const pickupaddress = params.has('pickUpAddressId');
  const payments = params.has('openPayments');
  const cartData = useLocalCart();
  const productsCount = cartData?.products?.length ? cartData.products.length : 0;
  const isCustomDomain = useIsCustomDomain();

  const themeName = useThemeName(shop);

  const ref = useAutoFontSize(themeName, label);

  const onClose = () => {

    params.delete('openMenu');
    router.push({ search: params.toString() }, undefined, { shallow: true });

  };

  const onBackAction = (event, goBack = false) => {

    if (goBack && !onBack) {

      router.back();
      event.preventDefault();
      event.stopPropagation();
      return;

    }
    if (onBack) {

      onBack();
      return;

    }
    if (showShop) {

      const url = isCustomDomain ? '/' : payload?.shop?.pathname;
      if (url) {

        router.replace(url);
        return;

      }
      router.push({ pathname: '/' });
      return;

    }
    router.back();

  };

  const shopIcon = payload?.shop?.icon;
  const emptyShopIcon = '/images/store/back.svg';

  const getStep = () => {

    if (stepper && !(address || pickupaddress)) {

      return 1;

    }
    if (stepper && (address || pickupaddress) && !payments) {

      return 2;

    }
    if (stepper && (address || pickupaddress) && payments) {

      return 3;

    }

  };

  const getStepName = () => {

    const step = getStep();
    if (step === 1) {

      return lang('store.menu.address');

    }
    if (step === 2) {

      return lang('store.words.orderSummary');

    }
    if (step === 3) {

      return lang('store.menu.payments');

    }

  };

  return (
    <div id="debby-header" className={cx('flexBetween', headerStyles)}>
      <div className={cx(styles.bigName, 'flexBetween fullWidth')}>
        <div className={styles.shop}>
          {showBack && (
            <img
              src="/images/feed/backButton.svg"
              alt=""
              data-id={dataId}
              data-value="shop button"
              role="none"
              onClick={(e) => onBackAction(e, true)}
            />
          )}
          {showShop && (
            <div className={cx({ [styles.default]: !shopIcon && !showBack })}>
              <img
                src={shopIcon || emptyShopIcon}
                alt=""
                data-id={dataId}
                className={styles.shopIcon}
                data-value="shop button"
                role="none"
                onClick={(e) => onBackAction(e, false)}
              />
            </div>
          )}
          <div
            ref={ref}
            role="none"
            data-id={dataId}
            data-value="shop button"
            onClick={(e) => onBackAction(e, false)}
            className={cx(styles.right)}
          >
            {label}
          </div>
        </div>
        <div className={styles.flex}>
          {stepper && (
            <>
              {text && (
                <>
                  {' '}
                  {text}
                  {' '}
                </>
              )}
              {showSearch && (
                <span
                  role="presentation"
                  className={cx(styles.newIcon)}
                  data-id={dataId}
                  data-value="search button"
                  onClick={() => {

                    router.push({
                      pathname: isCustomDomain
                        ? '/search' : `/${shop?.slug}/search`,
                      search: params.toString()
                    });

                  }}
                >
                  {newSearchIcon()}
                </span>
              )}
              {showChat && (
                <CustomShopChat
                  shopId={shop?._id}
                  shop={{
                    name: shop?.name,
                    url: getUserIcon(shop)
                  }}
                  slug={shop?.slug}
                />
              )}
              {showCart && (
                <div
                  className={cx(styles.newIcon, styles.relative)}
                  role="none"
                  onClick={() => {

                    if (isVisitor || isGuest) {

                      showLogin(shop);
                      return;

                    }

                    if (productsCount === 0) {
                      const url = isCustomDomain ? '/orders' : `/${(shop?.slug || payload?.shop?.name)}/orders`;
                      router.push(url);
                      return;
                    }
                    const url = isCustomDomain ? '/cart' : `${payload?.shop?.pathname}/cart`;
                    router.push(url);
                  }}
                >
                  {newCartIcon()}
                  {productsCount > 0 && <span className={styles.count}>{productsCount}</span>}
                </div>
              )}
            </>
          )}
          {stepper && (
            <div className={styles.shippingSteps}>
              <div className={styles.step}>{`${getStep()}/3`}</div>
              <div className={styles.stepName}>{getStepName()}</div>
            </div>
          )}
          {showMenu && (
            <Clickable
              className={cx(styles.newIcon)}
              onClick={() => {

                params.set('openMenu', 'true');
                router.push({
                  search: params.toString(),
                }, undefined, { shallow: true });

              }}
            >
              {hamIcon()}
            </Clickable>
          )}
          {showLogout && (
            <Button
              color="secondary"
              className={styles.showSearch}
              onClick={() => {

                params.set('showLogout', 'true');
                router.push({
                  search: params.toString(),
                });

              }}>
              <img src="/images/profile/more.svg" alt=""/>
            </Button>
          )}
        </div>
      </div>
      <MoreMenu
        id="simple-menu"
        keepMounted
        classes={{
          paper: styles.menu
        }}
        open={showLogoutMenu}
        onClose={() => router.back()}
      >
        <MenuItem>
          {/* <GoogleLogout */}
          {/*    clientId={'TODO: Google client id'} */}
          {/*    theme="dark" */}
          {/*    render={renderProps => ( */}
          {/*        <WindoButton */}
          {/*            onClick={onButtonClick(renderProps.onClick)} */}
          {/*            type="secondary" */}
          {/*            title="Logout" */}
          {/*        /> */}
          {/*    )} */}
          {/*    icon={false} */}
          {/*    className={styles.left10} */}
          {/*    onLogoutSuccess={logout} */}
          {/* > */}
          {/* </GoogleLogout> */}
        </MenuItem>
      </MoreMenu>
      {menu && <Menu shop={shop} type="right" onClose={onClose}/>}
    </div>
  );

}

Header.propTypes = {
  // showProfile: PropTypes.bool,
  showSearch: PropTypes.bool,
  showMenu: PropTypes.bool,
  showBack: PropTypes.bool,
  text: PropTypes.string,
  showLogout: PropTypes.bool,
  showCart: PropTypes.bool,
  showShop: PropTypes.bool,
  payload: PropTypes.object,
  dataId: PropTypes.string,
  headerStyles: PropTypes.any,
  label: PropTypes.string,
  onBack: PropTypes.func,
  showChat: PropTypes.bool,
  shop: PropTypes.object,
};

Header.defaultProps = {
  // showProfile: false,
  showSearch: false,
  showMenu: false,
  showBack: false,
  text: '',
  showLogout: false,
  showShop: false,
  showCart: false,
  payload: {},
  dataId: '',
  headerStyles: '',
  label: '',
  onBack: null,
  showChat: false,
  shop: {},
};

export default Header;
