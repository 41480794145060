import { useThemeName } from 'hooks/shop';
import CommonTopBar from 'components/store/CommonTopBar';
import SalviaTopBar from 'components/store/salvia/TopBarSalvia';
import { TopBarLupine } from 'components/store/lupine/TopBarLupine';

const topBars = {
  common: CommonTopBar,
  salvia: SalviaTopBar,
  lupine: TopBarLupine,
};

export function TopBar(props) {
  const theme = useThemeName(props.shop);

  const TopBarComponent = topBars[theme] || topBars.common;

  return (
    <TopBarComponent {...props} />
  );
}
