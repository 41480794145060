import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import styles from './Tabs.module.css';

function Tabs({
  selected, items, onSelect, tabsClass, tabClass
}) {

  return (
    <div className={cx(styles.Tabs, tabsClass)}>
      {items.map((x, i) => (
        <Clickable
          key={i}
          onClick={() => onSelect(x.value)}
          className={cx(styles.tab,
            {
              [styles.tab]: tabClass.length === 0,
              [styles.active]: selected === x.value,
            },
            tabClass)}
        >
          {x.label}
        </Clickable>
      ))}
    </div>
  );

}

Tabs.defaultProps = {
  tabsClass: '',
  tabClass: ''
};

Tabs.propTypes = {
  selected: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  tabsClass: PropTypes.string,
  tabClass: PropTypes.string
};

export default Tabs;
