import PropTypes from 'prop-types';
import { useQueryParams } from 'hooks/location';
import Header from 'components/shared/Header';
import styles from './common.module.css';

export default function StoreHeader({ shop, showSearch, showCart }) {
  const params = useQueryParams();
  const { uiConfig: { chat } = {} } = shop;

  return (
    <Header
      headerStyles={styles.headerStyles}
      dataId="shop_profile"
      payload={{
        shop: {
          pathname: `/${shop?.slug}`,
          search: params.toString(),
          shopId: shop?._id,
          icon: shop?.icon && shop?.icon[0]
        }
      }}
      showShop={true}
      showBack={false}
      showCart={showCart}
      showMenu={true}
      showSearch={showSearch}
      label={shop?.name}
      shop={shop}
      showChat={chat?.enabled}
    />
  );
}

StoreHeader.propTypes = {
  shop: PropTypes.object.isRequired,
  showCart: PropTypes.bool,
  showSearch: PropTypes.bool
};

StoreHeader.defaultProps = {
  showSearch: true,
  showCart: true
};
