import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { HelloBar } from 'components/store/HelloBar';
import StoreHeader from 'components/store/Header';
import Tabs from 'components/shop/Tabs';
import { useLanguageLoader } from 'contexts/language';
import { BorderBottom } from 'components/layout/BorderBottom';
import { Container } from 'components/layout/Container';
import Header from 'components/orders/Header';
import { useIsDesktop } from 'contexts/profile';
import styles from './TopBar.module.css';
import { useQueryParams } from 'hooks/location';
import CustomShopChat from 'components/shop/CustomShopChat';

export default function TopBar({
  shop, catalogs, showTabs, headerVariant, showStepper, isDigitalShipping,
  showSearch, showCart, showChat
}) {

  const lang = useLanguageLoader();
  const [selectedTab, setSelectedTab] = useState(1);
  const topBarRef = useRef();
  const isDesktop = useIsDesktop();
  const regex = /(<([^>]+)>)/ig;
  const isFilledAboutUs = !!shop?.about?.replace(regex, '').length > 0;
  const isFilledTerms = shop?.tncs?.length > 0;
  const params = useQueryParams();
  const chatOpenEnabled = params.has('openChat');

  const [topBarHeight, setTopBarHeight] = useState(0);

  useEffect(() => {
    if (topBarRef?.current) {
      setTopBarHeight(topBarRef?.current?.scrollHeight);
    }
  }, [topBarRef]);

  const handleScroll = () => {
    const height = topBarRef?.current?.scrollHeight;
    const ele1 = document.getElementById('shopScroll1')
      ?.getBoundingClientRect().top - height;
    const ele2 = document.getElementById('shopScroll2')
      ?.getBoundingClientRect().top - height;
    const ele3 = document.getElementById('shopScroll3')
      ?.getBoundingClientRect().top - height;
    const ele4 = document.getElementById('shopScroll4')
      ?.getBoundingClientRect().top - height;
    const ele5 = document.getElementById('shopScroll5')
      ?.getBoundingClientRect().top - height;
    const ele6 = document.getElementById('shopScroll6')
      ?.getBoundingClientRect().top - height;
    const ele7 = document.getElementById('shopScroll7')
      ?.getBoundingClientRect().top - height;
    const ele8 = document.getElementById('shopScroll8')
      ?.getBoundingClientRect().top - height;
    const ele9 = document.getElementById('shopScroll9')
      ?.getBoundingClientRect().top - height;
    const ele10 = document.getElementById('shopScroll10')
      ?.getBoundingClientRect().top - height;
    const filters = [ele1, ele2, ele3, ele4, ele5, ele6, ele7, ele8, ele9,ele10].filter(x => x <= 0);
    setSelectedTab(filters.length || 1);

  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, []);

  const getItems = () => {

    const items = [
      {
        label: lang('common.shop'),
        value: 1
      },
    ];
    if (catalogs.length > 0) {

      items.push({
        label: lang('store.menu.catalogs'),
        value: 2
      });

    }
    items.push({
      label: lang('store.menu.products'),
      value: 3
    });
    if (isFilledAboutUs) {

      items.push({
        label: lang('store.menu.aboutUs'),
        value: 4
      });

    }
    if (isFilledTerms) {

      items.push({
        label: lang('store.menu.termsPolicies'),
        value: 5
      });

    }
    items.push(
      {
        label: lang('store.menu.storeTimings'),
        value: 6
      }
    );
    items.push(
      {
        label: lang('store.menu.paymentMethod'),
        value: 7
      }
    );
    items.push(
      {
        label: lang('store.menu.contactUs'),
        value: 8
      }
    );
    items.push(
      {
        label: lang('store.headings.storeInformation'),
        value: 9
      }
    );
    items.push(
      {
        label: 'Customer Testimonials',
        value: 10
      }
    );
    return items;

  };

  return (
    <>
      <div ref={topBarRef} className={styles.topBar}>
        {headerVariant !== 'cart' && (
          <HelloBar shop={shop} />
        )}
        <Container>
          {headerVariant === 'normal' && <StoreHeader
            shop={shop} showCart={showCart}
            showSearch={showSearch} />}
          {headerVariant === 'cart' && (
            <Header
              dataId="cart"
              payload={{
                shop: {
                  pathname: `/${shop?.slug}`,
                  shopId: shop?._id,
                  icon: shop?.icon && shop?.icon[0]
                },
                shopId: shop?._id,
              }}
              showChat={!showStepper}
              showShop={true}
              showCart={!showStepper}
              showBack={!isDesktop}
              label={shop?.name}
              showProfile={false}
              showMenu={true}
              headerStyles={styles.cartHeaderStyles}
              showSearch={!showStepper}
              shop={shop}
              isDigitalShipping={isDigitalShipping}
            />
          )}
        </Container>
        <BorderBottom />
        {showTabs && (
          <Container className={styles.tabs}>
            <Tabs
              items={getItems()}
              tabClass={styles.tabClass}
              selected={selectedTab}
              onSelect={(e) => {
                const ele = document.getElementById(`shopScroll${e}`);
                if (ele) {
                  ele.scrollIntoView();
                  const y = ele.offsetTop;
                  window.scroll({
                    top: y - topBarHeight,
                    behavior: 'smooth'
                  });
                  setSelectedTab(e);
                }
              }}
            />
          </Container>
        )}
      </div>
      <div id="topBarEnd" />
      <div
        style={{ paddingTop: `${topBarRef?.current?.scrollHeight}px` }}
        className={styles.overflow}
      >
      </div>
      {showChat && chatOpenEnabled && (
        <CustomShopChat
          shopId={shop?._id}
          shop={{ name: shop?.name, }}
          slug={shop?.slug}
        />
      )}
    </>
  );

}

TopBar.propTypes = {
  shop: PropTypes.object.isRequired,
  catalogs: PropTypes.array,
  showTabs: PropTypes.bool,
  headerVariant: PropTypes.string,
  showStepper: PropTypes.bool,
  isDigitalShipping: PropTypes.bool
};

TopBar.defaultProps = {
  catalogs: [],
  showTabs: true,
  headerVariant: 'normal',
  showStepper: false,
  isDigitalShipping: false,
  showSearch: true,
  showCart: true
};
